import moment from 'moment-timezone';
import { UUID_REGEX_VALIDATION } from './IotMonitorConstants';
import { BigNumber } from 'bignumber.js';
import qs from 'qs';
import MessageType from '@jumbo/constants/MessageTypes';

export default {
  getActiveHardwareId(props) {
    let hardwareId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).hardwareId;
    if (hardwareId) {
      localStorage.setItem('hardwareId', hardwareId);
    } else {
      hardwareId = localStorage.getItem('hardwareId');
    }
    return hardwareId;
  },

  setActiveHardwareId(hardwareId) {
    if (hardwareId) {
      localStorage.setItem('hardwareId', hardwareId);
    } else {
      localStorage.removeItem('hardwareId');
    }
  },

  getActiveUrlParam(props, propName) {
    let value = qs.parse(props.location.search, { ignoreQueryPrefix: true })[propName];
    if (value) {
      return [value];
    }
  },

  formatDecimal(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  },

  formatDecimalInCents(valueInCents) {
    return this.formatDecimal(valueInCents / 100.0);
  },

  parseStringTwoDecimalsCurrencyToValueInCents(currencyFormattedStrValue) {
    if (!currencyFormattedStrValue) {
      return currencyFormattedStrValue;
    }
    let cleanStr = currencyFormattedStrValue.replace(/[,\\.]/g, '');
    let valueInCents = Number(cleanStr);
    return valueInCents;
  },

  clearCurrencyInputValue(inputValue) {
    if (!inputValue) {
      return inputValue;
    }
    const trimmedInputValue = inputValue.replace('R$ ', '');
    const normalizedInputValue = trimmedInputValue.includes(',') ? trimmedInputValue : `${trimmedInputValue},00`;
    const inputValueInCents = this.parseStringTwoDecimalsCurrencyToValueInCents(normalizedInputValue);
    return inputValueInCents;
  },

  momentUTC(serverDatetimeString) {
    if (!serverDatetimeString) {
      return serverDatetimeString;
    }
    if (!serverDatetimeString.endsWith('Z')) {
      serverDatetimeString = serverDatetimeString + 'Z';
    }
    return moment(serverDatetimeString).tz(moment.tz.guess());
  },

  formatDateDDMMYYYYHHMM(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }

    return this.momentUTC(datetimeToFormat).format('DD/MM/YYYY HH:mm');
  },

  formatDateDDMMYYYYHHMMSS(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    return this.momentUTC(datetimeToFormat).format('DD/MM/YYYY HH:mm:ss [GMT]z');
  },

  formatDateDDMMYYYYHHMMSSMMM(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    return this.momentUTC(datetimeToFormat).format('DD/MM/YYYY HH:mm:ss.SSS [GMT]z');
  },

  formatDateDDMMYYYY(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }

    return this.momentUTC(datetimeToFormat).format('DD/MM/YYYY');
  },

  formatMomentToQueryParam(momentDatetime) {
    if (!momentDatetime) {
      return '';
    }
    const formatted = momentDatetime.utcOffset(-180).format('YYYY-MM-DDTHH:mm:ss');
    return formatted;
  },

  formatInMilliseconds(timeInMilliseconds) {
    if (!timeInMilliseconds) {
      return timeInMilliseconds;
    }

    return `${timeInMilliseconds} ms`;
  },

  isUuidValid(stringUUID) {
    return UUID_REGEX_VALIDATION.test(stringUUID);
  },

  formatUuid(uuid) {
    if (!uuid || uuid.length <= 6 || !this.isUuidValid(uuid)) {
      return uuid;
    }
    let formattedUuid = uuid.substr(0, 3);
    formattedUuid += uuid.substr(uuid.length - 3);
    return formattedUuid.toUpperCase();
  },

  formatTransportModal(value) {
    switch (value) {
      case 'URBAN_BUS':
        return 'Ônibus Urbano';
      case 'ROAD_BUS':
        return 'Ônibus Rodoviário';
      case 'METRO':
        return 'Metrô/Ferroviário';
      default:
        return value;
    }
  },

  formatToReal(value) {
    return value ? `R$ ${value}` : '-';
  },

  formatIotMessageStatus(status) {
    switch (status) {
      case 'PENDING': {
        return 'Pendente';
      }
      case 'COMPLETED': {
        return 'Finalizado';
      }
      case 'ERROR': {
        return 'Erro';
      }
      case 'PROCESSING_ERROR': {
        return 'Erro de Processamento';
      }
      default: {
        return status;
      }
    }
  },

  formatIotMessageQueueStatus(status) {
    switch (status) {
      case 'PENDING': {
        return 'Pendente';
      }
      case 'DELIVERED': {
        return 'Entregue';
      }
      case 'ERROR': {
        return 'Erro';
      }
      case 'EXPIRED': {
        return 'Expirada';
      }
      case 'SENT': {
        return 'Enviado';
      }
      default: {
        return status;
      }
    }
  },

  formatDeviceType(deviceType) {
    switch (deviceType) {
      case 'MOBIPIX_APP': {
        return 'App Mobipix';
      }
      case 'TURNSTILE_IOT': {
        return 'IOT Foca Braun';
      }
      default: {
        return deviceType;
      }
    }
  },

  formatDeviceRegistrationStatus(deviceRegistrationStatus) {
    switch (deviceRegistrationStatus) {
      case 'PENDING':
        return 'Pendente';
      case 'ACTIVE':
        return 'Ativo';
      case 'MOVED_TO_ANOTHER_SERVER':
        return 'Movido para outro servidor';
      case 'INACTIVE':
        return 'Inativo';
      default:
        return deviceRegistrationStatus;
    }
  },

  formatHexadecimal2D(d) {
    const value = d / 256 + 1 / 512;
    return (
      '0x' +
      value
        .toString(16)
        .substring(2, 4)
        .toUpperCase()
    );
  },

  formatMessageType(type) {
    if (!type) {
      return type;
    }
    if (MessageType[type]) {
      if (MessageType[type].protoValue) {
        return MessageType[type].desc + ' (' + this.formatHexadecimal2D(MessageType[type].protoValue) + ')';
      } else {
        return MessageType[type].desc;
      }
    }
    return type;
  },

  formatCommands(command) {
    if (!command) {
      return command;
    }
    if (MessageType[command]) {
      return MessageType[command].desc;
    }
    if (command.match(/^[A-Z_]+$/)) {
      return 'Enviar ' + command;
    } else {
      return command;
    }
  },

  formatDirection(direction) {
    switch (direction) {
      case 'INBOUND': {
        return 'Recebimento';
      }
      case 'OUTBOUND': {
        return 'Envio';
      }
      default: {
        return direction;
      }
    }
  },

  convertHexStringToDecimalNumber(hexString) {
    return hexString ? parseInt(hexString, 16) : null;
  },

  convertBase64StringToByteArray(base64String) {
    if (!base64String) {
      return base64String;
    }
    const binaryString = atob(base64String);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  },

  convertTypedByteArrayToHexString(typedByteArray) {
    const hexArray = Array.from(typedByteArray, byte => `0${(byte & 0xff).toString(16)}`.slice(-2));
    return hexArray.join(':');
  },

  convertBase64StringToHexString(base64String) {
    const base64StringArrayBuffer = this.convertBase64StringToByteArray(base64String);
    const base64StringUint8Array = new Uint8Array(base64StringArrayBuffer);
    const hexString = this.convertTypedByteArrayToHexString(base64StringUint8Array);
    return hexString;
  },

  convertBase64StringToPlainText(base64String) {
    return base64String ? atob(base64String) : '';
  },

  convertDecimalNumberToHexString(decimalNumber) {
    return decimalNumber ? `0x${decimalNumber.toString(16)} (` + String(decimalNumber) + `)` : null;
  },

  formatBigNumberAbsToHexWithPadding(number) {
    if (!number) {
      return number;
    }

    const bn = new BigNumber(number);
    const longHexValue = '000000000000000' + bn.abs().toString(16);
    const hexValueCut = longHexValue.substr(longHexValue.length - 16);
    return hexValueCut;
  },

  formatNumberToHexWithPadding(number) {
    if (!number) {
      return number;
    }
    return (number >>> 0).toString(16);
  },

  formatHardwareId(hardwareId) {
    return hardwareId ? `0x${this.formatBigNumberAbsToHexWithPadding(hardwareId)} (${hardwareId})` : null;
  },

  formatHexadecimalValue(number) {
    return number ? `0x${this.formatNumberToHexWithPadding(number)} (${number})` : null;
  },

  formatMnemonic(mnemonic) {
    return mnemonic ? `${mnemonic}` : `-`;
  },

  formatString(str) {
    return str ? `${str}` : `-`;
  },

  formatReleaseTriggerMode(releaseTriggerMode) {
    switch (releaseTriggerMode) {
      case 'ACTIVE':
        return 'Ativo';
      case 'PASSIVE':
        return 'Passivo';
      case 'EXTERNAL':
        return 'Externo';
      case 'RELEASE_BUTTON':
        return 'Botoeira';
      default:
        return releaseTriggerMode;
    }
  },

  formatTurnstileReleaseOutcome(turnstileReleaseOutcome) {
    switch (turnstileReleaseOutcome) {
      case 'TURN':
        return 'Giro';
      case 'TIMEOUT':
        return 'Tempo Limite';
      case 'PENDING':
        return 'Pendente';
      default:
        return turnstileReleaseOutcome;
    }
  },

  formatReleaseConnectionState(releaseConnectionState) {
    switch (releaseConnectionState) {
      case 'ONLINE':
        return 'Online';
      case 'OFFLINE':
        return 'Offline';
      default:
        return releaseConnectionState;
    }
  },

  formatTransactionStatus(status) {
    switch (status) {
      case 'ERROR':
        return 'Erro';
      case 'CREATED':
        return 'Criada';
      case 'PENDING_TURN':
        return 'Aguardando giro';
      case 'COMPLETED':
        return 'Concluída';
      case 'VOID':
        return 'Cancelada';
      case 'DENIED':
        return 'Negada';
      default:
        return status;
    }
  },

  formatMobileConnectionType(mobileConnectionType) {
    if (mobileConnectionType === null) {
      return '-';
    }

    switch (mobileConnectionType) {
      case 'UNKNOWN':
        return 'Desconhecido';
      case 'GSM':
        return '2G';
      case 'LTE_CAT_M1':
        return '4g';
      case 'LTE_CAT_NB1':
        return '4G (NB1)';
      default:
        return mobileConnectionType;
    }
  },

  formatIotFileType(iotFileType) {
    switch (iotFileType) {
      case 'WALLET_HASH_FULL':
        return 'QRCode Hash - Completo';
      case 'NFCCARD_ID_FULL':
        return 'Cartão NFC - Completo';
      default:
        return iotFileType;
    }
  },

  formatIotFileStatus(iotFileStatus) {
    switch (iotFileStatus) {
      case 'CREATED':
        return 'Criada';
      case 'GENERATED':
        return 'Gerada';
      case 'ERROR':
        return 'Erro';
      case 'EXPIRED':
        return 'Expirada';
      default:
        return iotFileStatus;
    }
  },

  formatFileDeliveryStatus(fileDeliveryStatus) {
    switch (fileDeliveryStatus) {
      case 'PENDING':
        return 'Pendente';
      case 'TRANSFERRING':
        return 'Transferindo';
      case 'COMPLETED':
        return 'Completa';
      case 'ERROR':
        return 'Erro';
      case 'EXPIRED':
        return 'Expirada';
      default:
        return fileDeliveryStatus;
    }
  },

  formatReleaseSensorType(sensor) {
    switch (sensor) {
      case 'FC_43NA':
        return `${sensor} - Tipo de sinal negativo`;
      case 'FC_43NA_BUTTON':
        return `${sensor} - Tipo de sinal negativo + botoeira`;
      case 'FC_60CN':
        return `${sensor} - Tipo de sinal negativo`;
      case 'FC_60CN_INNOVATEK':
        return `${sensor} - Tipo de sinal negativo`;
      case 'FC_60CN_INNOVATEK_2S':
        return `${sensor} - Tipo de sinal negativo - Dois sinais de controle sensor 1 e 2`;
      case 'FC_60CN_INNOVATEK_2P':
        return `${sensor} - Tipo de sinal negativo - Um sinal de controle sensor 2`;
      case 'SPS_RELEASE_BUTTON':
        return `${sensor} - Inversão de tipo de sinal (entrada positiva) + botoeira`;
      case 'SENSOR_SPS':
        return `${sensor} - Inversão de tipo de sinal (entrada positiva) + não registra botoeira`;
      default:
        return sensor;
    }
  },

  flattenObject(toBeFlattenedObject) {
    const flattenedObject = {};
    const toBeFlattenedObjectProperties = Object.keys(toBeFlattenedObject);

    toBeFlattenedObjectProperties.forEach(property => {
      if (
        toBeFlattenedObject[property] !== null &&
        typeof toBeFlattenedObject[property] === 'object' &&
        !Array.isArray(toBeFlattenedObject[property])
      ) {
        const flattenedSubObject = this.flattenObject(toBeFlattenedObject[property]);
        const flattenedSubObjectProperties = Object.keys(flattenedSubObject);

        flattenedSubObjectProperties.forEach(flattenedSubObjectProperty => {
          flattenedObject[`${property}.${flattenedSubObjectProperty}`] = flattenedSubObject[flattenedSubObjectProperty];
        });
      } else {
        flattenedObject[property] = toBeFlattenedObject[property];
      }
    });

    return flattenedObject;
  },

  getOriginalFieldName(flattenedFieldName) {
    if (!flattenedFieldName) return flattenedFieldName;
    const splittedFlattenedFieldName = flattenedFieldName.split('.');
    return splittedFlattenedFieldName[splittedFlattenedFieldName.length - 1];
  },

  /**
   * Busca o indice do objeto em um array de colunas de MUI Datatable em que name = key.
   * Caso nao haja um nome que corresponda a chave pesquisada, a funcao retorna undefined.
   * @param {string} key - A chave a ser pesquisada em columns (comparando pelo atributo "name").
   * @param {Array<Object>} columns - Array de objects no estilo de MUI Datatable (todas colunas devem ter prop. "name"!).
   * @returns {number|undefined} O indice inteiro de key se esta em columns, caso contrario devolve undefined.
   */
  getColumnIndex(key, columns) {
    for (let index = 0; index < columns.length; index++) {
      if (columns[index].name === key) {
        return index;
      }
    }
    return undefined;
  },

  /**
   * Busca a lista de filtros ativos no filterList de MUI Datatable com base em uma chave "key" que deve pertencer
   * a columns. No caso de "key" ser igual a "serverTime", retorna um Array de objetos Moment.
   * Caso nao haja tal chave em columns, a funcao retorna undefined.
   * @param {string} key - A chave a ser pesquisada em columns (comparando pelo atributo "name").
   * @param {Array<Object>} columns - Array de objects no estilo de MUI Datatable (todas colunas devem ter prop. "name"!).
   * @param {Array<Array<>>} filterList - Array de Array contendo todos os filtros ativos.
   * @returns {Array<string>|Array<moment>|undefined} O array de filtros ativos se key esta em columns, caso contrario undefined.
   */
  getColumnFilter(key, columns, filterList) {
    const index = this.getColumnIndex(key, columns);
    if (index !== null || index !== undefined) {
      return filterList[index];
    }
    return undefined;
  },

  /**
   * Gera a query string a ser passada para o servidor.
   * @param {string} field - Campo da query.
   * @param {Object|any} operator - Seleciona o comportamento da funcao (geralmente string vazia para o caso else).
   * @param {Array<string>|Array<moment>} filterItems - Array com os filtros ativos para o campo fornecido.
   * @returns {string} Uma query string para o servidor.
   */
  reduceFilter(field, operator, filterItems) {
    if (typeof operator == 'object') {
      return `${field}.${operator[0]}=${moment(filterItems[0]).format()}&${field}.${operator[1]}=${moment(
        filterItems[1],
      ).format()}`;
    } else {
      return filterItems.reduce(
        (totalValue, currentValue) => `${totalValue}${field}=${encodeURIComponent(currentValue)}&`,
        '',
      );
    }
  },

  /**
   * Realiza os procedimentos padrao para os filtros ativos de uma coluna de columns onde column.name == key.
   * Se os filtros ativos existem, ele concatena mais uma query (gerada por key) a propriedade "str" de serverSideFilterObj
   * e adiciona o par key-valor do filtro a um objeto URLSearchParams para gerar a query da URL.
   * @param {string} key - A chave a ser pesquisada em columns (comparando pelo atributo "name").
   * @param {Array<Object>} columns - Array de objects no estilo de MUI Datatable (todas colunas devem ter prop. "name"!).
   * @param {Array<Array<>>} filterList - Array de Array contendo todos os filtros ativos.
   * @param {URLSearchParams} urlParams - Objeto que armazena os pares chave-valor para gerar a query da URL.
   * @param {Object} serverSideFilterParamName - Objeto que armazena a query para o servidor na propriedade "str".
   */
  mountServerSideUrlFilter(key, columns, filterList, urlParams, serverSideFilterParamName) {
    const activeFilters = this.getColumnFilter(key, columns, filterList);
    if (activeFilters?.length) {
      serverSideFilterParamName.str += this.reduceFilter(key, '', activeFilters);
      activeFilters.forEach(e => urlParams.append(key, e));
    }
  },
};
