import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_IOT_MESSAGES_QUEUE,
} from '../../@jumbo/constants/ActionTypes';
import axios from 'services/auth/jwt/config';

const iotMessagesQueueBaseEndpoint = 'device-messages-queue';

export const getIotMessagesQueue = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${iotMessagesQueueBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`);
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: GET_IOT_MESSAGES_QUEUE,
        payload: { data: res.data.data, totalCount: res.data.count },
      });
    } catch (error) {
      console.error('Error****:', error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: HIDE_MESSAGE });
    }
  };
};
