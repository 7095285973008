import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_LOCATIONS,
} from '@jumbo/constants/ActionTypes';
import { fetchError, showMessage } from 'redux/actions';
import axios from 'services/auth/jwt/config';

const locationsBaseEndpoint = 'locations';


export const getLocations = () => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${locationsBaseEndpoint}`);
      const responseBody = res.data;

      if (responseBody) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_LOCATIONS, payload: responseBody });
      } else {
        console.error('Error****:', responseBody);
        dispatch(fetchError(responseBody));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};