import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      height: '100%',
      color: '#ff4046',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      flexDirection: 'column',
      fontSize: '1.25rem',
      textAlign: 'center',
    },
    titleIcon: {
      backgroundColor: '#ffe2e2',
      color: '#ff4046',
      '&:hover': {
        backgroundColor: '#ffe2e2',
        cursor: 'default',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '8rem',
      },
    },
  };
});

const ErrorView = ({ error }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <IconButton aria-label="delete" disableRipple className={classes.titleIcon}>
        <ErrorOutlineIcon />
      </IconButton>
      <Box mt={6}>Oops! Um erro inesperado ocorreu: {error.message}</Box>
      <Box>Tente novamente mais tarde</Box>
    </Box>
  );
};

export default ErrorView;
