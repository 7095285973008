import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import IotMessage from './IotMessage';
import IotMessages from './IotMessages';
import IotMessagesQueue from './IotMessagesQueue';
import Devices from './Devices';
import MessagesWithReplies from './MessagesWithReplies';
import TurnstileReleaseMessages from './TurnstileReleaseMessages';
import TransactionMessages from './TransactionMessages';
import Transactions from './Transactions';
import Transaction from './Transaction';
import Organizations from './Organizations'
import Locations from './Locations'

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    iotMessage: IotMessage,
    iotMessages: IotMessages,
    iotMessagesQueue: IotMessagesQueue,
    devices: Devices,
    messagesWithReplies: MessagesWithReplies,
    turnstileReleaseMessages: TurnstileReleaseMessages,
    transactionMessages: TransactionMessages,
    transactions: Transactions,
    transaction: Transaction,
    organizations: Organizations,
    locations: Locations,
  });
