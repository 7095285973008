import { CLEAR_STATE, GET_IOT_MESSAGES_QUEUE } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  iotMessagesQueue: {
    data: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_IOT_MESSAGES_QUEUE: {
      return {
        ...state,
        iotMessagesQueue: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
