import {
  CLEAR_STATE,
  GET_TRANSACTION,
  REQUEST_GET_TRANSACTION,
  REQUEST_GET_TRANSACTION_FAILURE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  transaction: {},
  loadingTransaction: false,
  loadingTransactionError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_TRANSACTION: {
      return {
        ...state,
        transaction: action.payload,
        loadingTransaction: false,
        loadingTransactionError: false,
      };
    }

    case REQUEST_GET_TRANSACTION: {
      return {
        ...state,
        loadingTransaction: true,
        loadingTransactionError: false,
      };
    }

    case REQUEST_GET_TRANSACTION_FAILURE: {
      return {
        ...state,
        loadingIotMessage: false,
        loadingIotMessageError: true,
      };
    }

    default: {
      return state;
    }
  }
};
