import{
    GET_ORGANIZATIONS
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    organizations:{
    data:[],
    }
};

export default(state=INIT_STATE, action) => {
    switch(action.type){
        case GET_ORGANIZATIONS :{
            return{
                ...state,
                organizations:{
                    ...state.organizations,
                    data: action.payload,
                },
            };
        }
        default: {
              return state;
        }
    }
};