import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_TRANSACTION,
  REQUEST_GET_TRANSACTION,
  REQUEST_GET_TRANSACTION_FAILURE,
} from '../../@jumbo/constants/ActionTypes';
import axios from 'services/auth/jwt/config';

const transactionPath = 'transactions';

export const getTransaction = id => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTION });
    try {
      const res = await axios.get(`${transactionPath}/${id}?withMessages=true`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTION, payload: res.data });
      } else {
        console.log('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch({ type: REQUEST_GET_TRANSACTION_FAILURE });
        dispatch({ type: HIDE_MESSAGE });
      }
    } catch (error) {
      console.log('Error****:', error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: REQUEST_GET_TRANSACTION_FAILURE });
      dispatch({ type: HIDE_MESSAGE });
    }
  };
};
