import React from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MessageIcon from '@material-ui/icons/Message';
import QueueIcon from '@material-ui/icons/ArtTrack';
import ForumIcon from '@material-ui/icons/Forum';
import RouterIcon from '@material-ui/icons/Router';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const navigationMenus = [
    {
      name: '',
      type: 'section',
      children: [
        {
          name: 'Tempo Real',
          icon: <RouterIcon />,
          type: 'item',
          link: '/dashboard',
        },
        {
          name: 'Dispositivos',
          icon: <RouterIcon />,
          type: 'item',
          link: '/devices',
        },
        {
          name: 'Transações',
          icon: <AttachMoneyIcon />,
          type: 'item',
          link: '/transactions',
        },
        {
          name: 'Fila de Mensagens IOT',
          icon: <QueueIcon />,
          type: 'item',
          link: '/iot-messages-queue',
        },
        {
          name: 'Mensagens e Respostas',
          icon: <ForumIcon />,
          type: 'item',
          link: '/messages-with-replies',
        },
        {
          name: 'Mensagens IOT',
          icon: <MessageIcon />,
          type: 'item',
          link: '/iot-messages',
        },
        {
          name: 'Fluxo de Liberação de Catracas',
          icon: <DirectionsBusIcon />,
          type: 'item',
          link: '/turnstile-release-messages',
        },
        {
          name: 'Fluxo de Transações',
          icon: <AddShoppingCartIcon />,
          type: 'item',
          link: '/transaction-messages',
        },
        {
          name: 'Arquivos IOT',
          icon: <DescriptionIcon />,
          type: 'item',
          link: '/iot-files',
        },
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
