import { CLEAR_STATE, GET_IOT_MESSAGES, GET_IOT_MESSAGE_DETAIL } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  iotMessages: {
    data: [],
    totalCount: 0,
  },
  iotMessagesWithDetails: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_IOT_MESSAGES: {
      return {
        ...state,
        iotMessages: action.payload,
      };
    }

    case GET_IOT_MESSAGE_DETAIL: {
      const newMessageWithDetail = action.payload;
      const alreadyHaveNewMessageWithDetail = state.iotMessagesWithDetails.find(
        messageWithDetail => messageWithDetail.id == newMessageWithDetail.id,
      );
      const newIotMessagesWithDetails = alreadyHaveNewMessageWithDetail
        ? state.iotMessagesWithDetails
        : [...state.iotMessagesWithDetails, action.payload];

      return {
        ...state,
        iotMessagesWithDetails: newIotMessagesWithDetails,
      };
    }

    default: {
      return state;
    }
  }
};
