export const UUID_REGEX_VALIDATION = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

// validate iso datetime without millis and timezone. ex: "2020-12-31T14:25:59"
export const ISO_DATETIME_REGEX_VALIDATION = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/;

export const IOT_FILE_TYPE = ['WALLET_HASH_FULL', 'NFCCARD_ID_FULL'];

export const IOT_FILE_STATUS = ['CREATED', 'GENERATED', 'ERROR', 'EXPIRED'];

export const IOT_FILE_DELIVERY_STATUS = ['PENDING', 'TRANSFERRING', 'COMPLETED', 'ERROR', 'EXPIRED'];

export const DEVICE_REGISTRATION_STATUS = ['PENDING', 'ACTIVE', 'MOVED_TO_ANOTHER_SERVER', 'INACTIVE'];

/**
 * Lista dos sensores implementados
 */
export const RELEASE_SENSOR_TYPE = [
  'FC_43NA',
  'FC_43NA_BUTTON',
  'FC_60CN',
  'FC_60CN_INNOVATEK',
  'FC_60CN_INNOVATEK_2S',
  'FC_60CN_INNOVATEK_2P',
  'SPS_RELEASE_BUTTON',
  'SENSOR_SPS',
];
