import React, { Suspense, lazy } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ErrorView from 'util/ErrorView';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const buildLazyComponent = importer => withErrorBoundary(lazy(importer), { FallbackComponent: ErrorView });

const Login = buildLazyComponent(() => import('./Auth/Login'));
const Register = buildLazyComponent(() => import('./Auth/Register'));
const ForgotPasswordPage = buildLazyComponent(() => import('./Auth/ForgotPassword'));
const IotMessage = buildLazyComponent(() => import('./Pages/IotMessage'));
const IotMessages = buildLazyComponent(() => import('./Pages/IotMessages'));
const IotMessagesQueue = buildLazyComponent(() => import('./Pages/IotMessagesQueue'));
const MessagesWithReplies = buildLazyComponent(() => import('./Pages/MessagesWithReplies'));
const TurnstileReleaseMessages = buildLazyComponent(() => import('./Pages/TurnstileReleaseMessages'));
const TransactionMessages = buildLazyComponent(() => import('./Pages/TransactionMessages'));
const Transactions = buildLazyComponent(() => import('./Pages/Transactions'));
const TransactionDetail = buildLazyComponent(() => import('./Pages/TransactionDetail'));
const DevicesRealTime = buildLazyComponent(() => import('./Pages/DevicesRealTime'));
const Devices = buildLazyComponent(() => import('./Pages/Devices'));
const IotFiles = buildLazyComponent(() => import('./Pages/IotFiles'));
const Error404 = buildLazyComponent(() => import('./Pages/404'));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { token, authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        token && authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { token, authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (
    token &&
    authUser &&
    (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password')
  ) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/signin" component={Login} />
        <Route exact path="/signup" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute exact path="/iot-message" component={IotMessage} />
        <RestrictedRoute exact path="/transaction/:id" component={TransactionDetail} />
        <RestrictedRoute exact path="/iot-messages/:hardwareId?" component={IotMessages} />
        <RestrictedRoute exact path="/messages-with-replies" component={MessagesWithReplies} />
        <RestrictedRoute exact path="/turnstile-release-messages" component={TurnstileReleaseMessages} />
        <RestrictedRoute exact path="/transaction-messages" component={TransactionMessages} />
        <RestrictedRoute exact path="/transactions" component={Transactions} />
        <RestrictedRoute exact path="/dashboard" component={DevicesRealTime} />
        <RestrictedRoute exact path="/iot-files" component={IotFiles} />
        <RestrictedRoute exact path="/iot-messages-queue" component={IotMessagesQueue} />
        <RestrictedRoute exact path="/devices" component={Devices} />
        <RestrictedRoute component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
