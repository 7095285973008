const MessageType = {
  UNKNOWN: { desc: 'Desconhecido', protoValue: null },
  MSG_PING: { desc: 'Ping', protoValue: 0x01 },
  MSG_LOCAL_COMMAND: { desc: 'Comando local', protoValue: 0x02 },
  MSG_REMOTE_COMMAND: { desc: 'Comando remoto', protoValue: 0x03 },
  MSG_CONFIG_REMOTE_SERVER: {
    desc: 'Configuração de servidor remoto',
    protoValue: 0x04,
  },
  MSG_GET_CURRENT_CONFIG: {
    desc: 'Consulta de configuração atual',
    protoValue: 0x05,
  },
  MSG_CONFIG_SENSORS: { desc: 'Configuração de sensores', protoValue: 0x06 },
  MSG_REMOTE_TRANSACTION: { desc: 'Transação remota', protoValue: 0x07 },
  MSG_TURNSTILE_TURN: { desc: 'Giro de catraca', protoValue: 0x08 },
  MSG_LOCAL_TRANSACTION: { desc: 'Transação local', protoValue: 0x09 },
  MSG_OFFLINE_LOCAL_TRANSACTION: {
    desc: 'Transação local offline',
    protoValue: 0x0a,
  },
  MSG_TURNSTILE_TURN_TIMEOUT: {
    desc: 'Giro de Catraca (não realizado - timeout)',
    protoValue: 0x0c,
  },
  MSG_UPDATE_FIRMWARE: { desc: 'Atualização de firmware', protoValue: 0x0b },
  MSG_FIRMWARE_UPDATE_COMPLETED: {
    desc: 'Atualização de firmware concluída',
    protoValue: 0x0d,
  },
  MSG_FILE_PAYMENT_ARRANGEMENT_KEYS: {
    desc: 'Arquivo de arranjo de pagamento',
    protoValue: 0x0f,
  },
  MSG_REQUEST_FILE: { desc: 'Solicitação de arquivo', protoValue: 0x10 },
  MSG_SYNC_TRANSACTION_STATUS: {
    desc: 'Sincronização de transação',
    protoValue: 0x11,
  },
  MSG_SYNC_ERROR: {
    desc: 'Notificação de erro de sincronização',
    protoValue: 0x12,
  },
  MSG_GET_CURRENT_LIB_STATE: {
    desc: 'Consulta do estado da biblioteca',
    protoValue: 0x13,
  },
  MSG_RESET_LIB_STATE: {
    desc: 'Reiniciar o estado da biblioteca',
    protoValue: 0x14,
  },
  MSG_REBOOT_IOT: { desc: 'Reiniciar IOT', protoValue: 0x15 },
  MSG_EVENT_LOG: { desc: 'Log', protoValue: 0x16 },
  MSG_TURNSTILE_RELEASE: { desc: 'Liberação de catraca', protoValue: 0x17 },
  MSG_GET_PAYMENT_ARRANGEMENT_KEYS: {
    desc: 'Consulta de chaves de criptografia',
    protoValue: 0x18,
  },
  MSG_FILE_GET: {
    desc: 'Consulta de arquivo (lista de restritos)',
    protoValue: 0x21,
  },
  MSG_FILE_CREATE_NEW: {
    desc: 'Criação de arquivo',
    protoValue: 0x19,
  },
  MSG_FILE_DATA_CONTENT: {
    desc: 'Transferência de conteúdo de arquivo',
    protoValue: 0x20,
  },
  MSG_TURNSTILE_TURN_EXTERNAL: {
    desc: 'Giro de Catraca - Liberação externa',
    protoValue: 0x22,
  },
  MSG_SYNC_LOCAL_EVENT: {
    desc: 'Sincronização de evento local (IOT)',
    protoValue: 0x23,
  },
  MSG_STATUS_REMOTE_TRANSACTION: {
    desc: 'Consultar status de transação (IOT)',
    protoValue: 0x24,
  },
  MSG_GET_TRIP_STATE: {
    desc: 'Verificação do estado da viagem (IOT)',
    protoValue: 0x25,
  },
  MSG_FORMAT_IOT_MEMORY: {
    desc: 'Formatar memoria persistente do IOT',
    protoValue: 0x26,
  },
  MSG_GET_STATISTICS: {
    desc: 'Consulta de estatisticas do IOT',
    protoValue: 0x27,
  },
};

const lowerCaseFirstLetter = string => string.charAt(0).toLowerCase() + string.slice(1);

Object.keys(MessageType).forEach(messageTypeKey => {
  const match = messageTypeKey.match(/^MSG_((?!(REPLY)).*)$/);
  if (match) {
    const messageReplyKey = 'MSG_REPLY_' + match[1];
    MessageType[messageReplyKey] = {
      desc: 'Resp. ' + lowerCaseFirstLetter(MessageType[messageTypeKey].desc),
      reply: true,
      protoValue: MessageType[messageTypeKey].protoValue | 0x80,
    };
    Object.freeze(MessageType[messageReplyKey]);
  } else {
    MessageType[messageTypeKey].reply = false;
    Object.freeze(MessageType[messageTypeKey]);
  }
});

export default Object.freeze(MessageType);
