import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setToken, setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import { CLEAR_STATE } from '@jumbo/constants/ActionTypes';
import React from 'react';
import axios from './config';
import { history } from 'redux/store';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    // this feature does not exists yet...
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ login, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('auth', {
            username: login,
            password: password,
          })
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(setToken(data));
              dispatch(JWTAuth.getAuthUser(false));
            } else {
              console.error(data);
              dispatch(fetchError(data));
            }
          })
          .catch(function(error) {
            console.error(error);
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        console.error(error);
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(setAuthUser(null));
      localStorage.removeItem('token');
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('me')
        .then(res => {
          if (res.data) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(res.data));
            dispatch(updateLoadUser(true));
          } else {
            dispatch(fetchError('Error fetching current user data.'));
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          dispatch(setAuthUser(null));
          dispatch(updateLoadUser(true));
          if (error.response.status === 401) {
            localStorage.removeItem('token');
          }
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
