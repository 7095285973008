import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_DEVICES,
  GET_DEVICES_REAL_TIME,
  GET_DEVICE_LATEST_MESSAGES_REQUEST,
  GET_DEVICE_LATEST_MESSAGES_SUCCESS,
  GET_DEVICE_LATEST_MESSAGES_ERROR,
  SHOW_IOT_DEVICE_COMMAND_DIALOG,
  HIDE_IOT_DEVICE_COMMAND_DIALOG,
  DEVICE_COMMAND_ERROR,
  UPDATE_DEVICES_TABLE_STATE,
  GET_IOT_DEVICE,
  REQUEST_IOT_DEVICE,
  REQUEST_IOT_DEVICE_FAILURE,
  UPDATE_IOT_DEVICE,
  REQUEST_UPDATE_IOT_DEVICE,
  REQUEST_UPDATE_IOT_DEVICE_FAILURE,
  SHOW_UPDATE_IOT_DEVICE_FORM,
  HIDE_UPDATE_IOT_DEVICE_FORM,
  CMD_RELEASE_TURNSTILE,
  CMD_UPDATE_DEVICE_FIRMWARE,
  CMD_CONFIG_REMOTE_SERVER,
  CMD_CONFIG_SENSORS,
  CMD_DEVICE_GENERIC,
  CMD_GET_CURRENT_CONFIG,
  CMD_GET_CURRENT_LIB_STATE,
  CMD_RESET_LIB_STATE,
  CMD_REBOOT_IOT,
  CMD_GET_PAYMENT_ARRANGEMENT_CONFIG,
  CMD_SEND_PAYMENT_ARRANGEMENT_CONFIG,
  CMD_SEND_TRIP_STATUS,
  CMD_FILE_GET,
  CMD_FILE_CREATE_NEW,
  CMD_GET_TRIP_STATE,
  CMD_SYNC_TRIP_STATE,
  CMD_FORMAT_IOT_MEMORY,
} from '@jumbo/constants/ActionTypes';
import { fetchError, showMessage } from 'redux/actions';
import axios from 'services/auth/jwt/config';

const devicesBaseEndpoint = 'device-status';

export const showIotDeviceCommandDialog = iotDeviceHardwareId => ({
  type: SHOW_IOT_DEVICE_COMMAND_DIALOG,
  payload: iotDeviceHardwareId,
});

export const hideIotDeviceCommandDialog = () => ({
  type: HIDE_IOT_DEVICE_COMMAND_DIALOG,
});

export const showUpdateIotDeviceForm = iotDeviceHardwareId => ({
  type: SHOW_UPDATE_IOT_DEVICE_FORM,
  payload: iotDeviceHardwareId,
});

export const hideUpdateIotDeviceForm = () => ({
  type: HIDE_UPDATE_IOT_DEVICE_FORM,
});

export const getDevicesWithRealtimeData = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${devicesBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`);
      const responseBody = res.data;

      if (responseBody) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_DEVICES_REAL_TIME, payload: responseBody });

        const devicesIdentifiers = responseBody.data.map(device => ({
          hardwareId: device.hardwareId,
          deviceType: device.deviceType,
        }));

        dispatch(getDevicesLatestMessages(devicesIdentifiers));
      } else {
        console.error('Error****:', responseBody);
        dispatch(fetchError(responseBody));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};

export const getDevicesLatestMessages = devicesIdentifiers => dispatch => {
  const requests = [];

  for (const deviceIdentifier of devicesIdentifiers) {
    dispatch({
      type: GET_DEVICE_LATEST_MESSAGES_REQUEST,
      payload: deviceIdentifier,
    });
    requests.push({
      request: axios.get(
        `${devicesBaseEndpoint}/latest-message?hardwareId=${deviceIdentifier.hardwareId}&deviceType=${deviceIdentifier.deviceType}`,
      ),
      deviceIdentifier: deviceIdentifier,
    });
  }

  for (const request of requests) {
    request.request
      .then(res => {
        dispatch({
          type: GET_DEVICE_LATEST_MESSAGES_SUCCESS,
          payload: { deviceIdentifier: request.deviceIdentifier, data: res.data },
        });
      })
      .catch(_e => {
        dispatch({
          type: GET_DEVICE_LATEST_MESSAGES_ERROR,
          payload: request.deviceIdentifier,
        });
      });
  }
};

export const getDevices = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${devicesBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`);
      const responseBody = res.data;

      if (responseBody) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_DEVICES, payload: responseBody });
      } else {
        console.error('Error****:', responseBody);
        dispatch(fetchError(responseBody));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};

export const freeDeviceTurnstile = (deviceHardwareId, deviceType, serverTransactionId) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages/release-turnstile`, {
        deviceType: deviceType,
        hardwareId: deviceHardwareId,
        serverTransactionId: serverTransactionId,
      });
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CMD_RELEASE_TURNSTILE, payload: res.data });
        dispatch(showMessage('Catraca Liberada!'));
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};

export const sendStatusRemoteCommand = (hardwareId, deviceType, localTransactionId, serverTransactionId) => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    await axios.post('device-messages/status-remote-transaction', {
      deviceType,
      hardwareId,
      localTransactionId,
      serverTransactionId
    });
    dispatch({ type: FETCH_SUCCESS });
    dispatch(showMessage('Comando enviado'));
    dispatch(hideIotDeviceCommandDialog());
  } catch (error) {
    console.error('Error****:', error);
    dispatch(fetchError(error.message));
  }
};

export const getFile = (deviceHardwareId, deviceType, fileId) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages/file-get`, {
        deviceType: deviceType,
        hardwareId: deviceHardwareId,
        fileId: fileId,
      });
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CMD_FILE_GET, payload: res.data });
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};

export const createFile = (deviceHardwareId, deviceType, fileId) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages/file-create-new`, {
        deviceType: deviceType,
        hardwareId: deviceHardwareId,
        fileId: fileId,
      });
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CMD_FILE_CREATE_NEW, payload: res.data });
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};

export const updateDeviceFirmware = data => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages/firmware-update`, data);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CMD_UPDATE_DEVICE_FIRMWARE, payload: res.data });
        dispatch(showMessage('Firmware Atualizado!'));
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      console.log('Error****:', error.response && error.response.data);
      dispatch(fetchError(error.message));
      dispatch({
        type: DEVICE_COMMAND_ERROR,
        payload: error.response && error.response.data,
      });
    }
  };
};

export const configRemoteServer = data => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages/config-remote-server`, data);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CMD_CONFIG_REMOTE_SERVER, payload: res.data });
        dispatch(showMessage('Comando Enviado!'));
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      console.log('Error****:', error.response && error.response.data);
      dispatch(fetchError(error.message));
      dispatch({
        type: DEVICE_COMMAND_ERROR,
        payload: error.response && error.response.data,
      });
    }
  };
};

export const formatIOTMemory = data => {
  return sendDeviceDataCommand(CMD_FORMAT_IOT_MEMORY, "format-iot-memory", data);
};

export const sendDeviceDataCommand = (type, path, data) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages/` + path, data);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: type, payload: res.data });
        dispatch(showMessage('Comando Enviado!'));
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      console.log('Error****:', error.response && error.response.data);
      dispatch(fetchError(error.message));
      dispatch({
        type: DEVICE_COMMAND_ERROR,
        payload: error.response && error.response.data,
      });
    }
  };
};

export const sendDeviceGenericCommand = data => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(`device-messages`, data);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CMD_DEVICE_GENERIC, payload: res.data });
        dispatch(showMessage('Comando enviado com sucesso!'));
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      console.log('Error****:', error.response && error.response.data);
      dispatch(fetchError(error.message));
      dispatch({
        type: DEVICE_COMMAND_ERROR,
        payload: error.response && error.response.data,
      });
    }
  };
};

export const updateDevicesTableState = devicesTableState => ({
  type: UPDATE_DEVICES_TABLE_STATE,
  payload: devicesTableState,
});

export const getIotDevice = iotDeviceId => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_IOT_DEVICE });
    try {
      const res = await axios.get(`devices/${iotDeviceId}`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_IOT_DEVICE,
          payload: res.data,
        });
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
      dispatch({ type: REQUEST_IOT_DEVICE_FAILURE, payload: error });
    }
  };
};

export const updateIotDevice = (data, { page, size, sort, filter }) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_UPDATE_IOT_DEVICE });
    try {
      const res = await axios.patch('devices', data);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_IOT_DEVICE, payload: res.data });
        dispatch(showMessage('Dispositivo atualizado!'));
        dispatch(hideUpdateIotDeviceForm());
        dispatch(getDevices(page, size, sort, filter));
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
      dispatch({ type: REQUEST_UPDATE_IOT_DEVICE_FAILURE, payload: error });
    }
  };
};

export const startProcessDeviceMessageTransactions = hardwareId => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_UPDATE_IOT_DEVICE });
    try {
      const res = await axios.post(`transactions/process?hardwareId=${hardwareId}`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch(showMessage('Executando o processamento das mensagens do device!'));
        dispatch(hideUpdateIotDeviceForm());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
      dispatch({ type: REQUEST_UPDATE_IOT_DEVICE_FAILURE, payload: error });
    }
  };
};

export const configSensors = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(deviceHardwareId, deviceType, `device-messages/config-sensors`, CMD_CONFIG_SENSORS);
};

export const getCurrentConfig = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(deviceHardwareId, deviceType, `device-messages/get-current-config`, CMD_GET_CURRENT_CONFIG);
};

export const getCurrentLibState = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(
    deviceHardwareId,
    deviceType,
    `device-messages/get-current-lib-state`,
    CMD_GET_CURRENT_LIB_STATE,
  );
};

export const getCurrentPaymentArrangementsConfig = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(
    deviceHardwareId,
    deviceType,
    `device-messages/get-payment-arrangements-config`,
    CMD_GET_PAYMENT_ARRANGEMENT_CONFIG,
  );
};

export const sendPaymentArrangementsConfig = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(
    deviceHardwareId,
    deviceType,
    `device-messages/send-payment-arrangements-config`,
    CMD_SEND_PAYMENT_ARRANGEMENT_CONFIG,
  );
};

export const sendTripStatus = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(deviceHardwareId, deviceType, `device-messages/send-trip-status`, CMD_SEND_TRIP_STATUS);
};

export const getTripState = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(
    deviceHardwareId,
    deviceType,
    `device-messages/get-trip-state?force=false`,
    CMD_GET_TRIP_STATE,
  );
};

export const getAndSyncTripState = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(
    deviceHardwareId,
    deviceType,
    `device-messages/get-trip-state?force=true`,
    CMD_SYNC_TRIP_STATE,
  );
};

export const resetLibState = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(deviceHardwareId, deviceType, `device-messages/reset-lib-state`, CMD_RESET_LIB_STATE);
};

export const resetLibStateResendTransactions = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(deviceHardwareId, deviceType, `device-messages/reset-lib-state?resetFlags=1`, CMD_RESET_LIB_STATE);
};

export const resetLibStateResendEvents = (deviceHardwareId, deviceType) => {
  return executeSingleCommand(deviceHardwareId, deviceType, `device-messages/reset-lib-state?resetFlags=2`, CMD_RESET_LIB_STATE);
};

export const rebootIot = (deviceHardwareId, deviceType, allowSendToQueue) => {
  return sendDeviceDataCommand(CMD_REBOOT_IOT, "reboot-iot", {
    deviceType: deviceType,
    hardwareId: deviceHardwareId,
    allowSendToQueue: allowSendToQueue
  });
};

const executeSingleCommand = (deviceHardwareId, deviceType, cmdPath, actionType) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.post(cmdPath, {
        deviceType: deviceType,
        hardwareId: deviceHardwareId,
      });
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: actionType, payload: res.data });
        dispatch(showMessage('Comando enviado!'));
        dispatch(hideIotDeviceCommandDialog());
      } else {
        console.error('Error****:', res.data);
        dispatch(fetchError(res.data));
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError(error.message));
    }
  };
};
