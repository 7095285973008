import { CLEAR_STATE, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  message: '',
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }
    case SHOW_MESSAGE: {
      return { ...state, message: action.payload };
    }
    case FETCH_START: {
      return { ...state, error: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
};
