export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const SET_USER_TOKEN = 'set_user_token';
export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

//MOBIPIX IOT MONITOR ACTIONS
export const CLEAR_STATE = 'clear_state';

//IOT MESSAGE
export const GET_IOT_MESSAGE = 'get_iot_message';
export const REQUEST_GET_IOT_MESSAGE = 'request_get_iot_message';
export const REQUEST_GET_IOT_MESSAGE_FAILURE = 'request_get_iot_message_failure';

//IOT MESSAGES
export const GET_IOT_MESSAGES = 'get_iot_messages';
export const GET_IOT_MESSAGE_DETAIL = 'get_iot_message_detail';

//IOT MESSAGES QUEUE
export const GET_IOT_MESSAGES_QUEUE = 'get_iot_messages_queue';

//DEVICES
export const GET_DEVICES = 'get_devices';
export const GET_DEVICES_REAL_TIME = 'get_devices_real_time';
export const GET_DEVICE_LATEST_MESSAGES_REQUEST = 'get_device_latest_messages_request';
export const GET_DEVICE_LATEST_MESSAGES_SUCCESS = 'get_device_latest_messages_success';
export const GET_DEVICE_LATEST_MESSAGES_ERROR = 'get_device_latest_messages_error';
export const SHOW_IOT_DEVICE_COMMAND_DIALOG = 'show_iot_device_command_dialog';
export const HIDE_IOT_DEVICE_COMMAND_DIALOG = 'hide_iot_device_command_dialog';
export const DEVICE_COMMAND_ERROR = 'device_command_error';
export const UPDATE_DEVICES_TABLE_STATE = 'update_devices_table_state';
export const GET_IOT_DEVICE = 'get_iot_device';
export const REQUEST_IOT_DEVICE = 'request_get_iot_device';
export const REQUEST_IOT_DEVICE_FAILURE = 'request_get_iot_device_failure';
export const UPDATE_IOT_DEVICE = 'update_iot_device';
export const REQUEST_UPDATE_IOT_DEVICE = 'request_update_iot_device';
export const REQUEST_UPDATE_IOT_DEVICE_FAILURE = 'request_update_iot_device_failure';
export const SHOW_UPDATE_IOT_DEVICE_FORM = 'show_update_iot_device_form';
export const HIDE_UPDATE_IOT_DEVICE_FORM = 'hide_update_iot_device_form';

//DEVICES -> COMMANDS
export const CMD_RELEASE_TURNSTILE = 'cmd_release_turnstile';
export const CMD_UPDATE_DEVICE_FIRMWARE = 'cmd_update_device_firmware';
export const CMD_CONFIG_REMOTE_SERVER = 'cmd_config_remote_server';
export const CMD_CONFIG_SENSORS = 'cmd_config_sensors';
export const CMD_GET_CURRENT_CONFIG = 'cmd_get_current_config';
export const CMD_GET_CURRENT_LIB_STATE = 'cmd_get_current_lib_state';
export const CMD_RESET_LIB_STATE = 'cmd_reset_lib_state';
export const CMD_REBOOT_IOT = 'cmd_reboot_iot';
export const CMD_DEVICE_GENERIC = 'cmd_device_generic';
export const CMD_GET_PAYMENT_ARRANGEMENT_CONFIG = 'cmd_get_payment_arrangement_config';
export const CMD_SEND_PAYMENT_ARRANGEMENT_CONFIG = 'cmd_send_payment_arrangement_config';
export const CMD_SEND_TRIP_STATUS = 'cmd_send_trip_status';
export const CMD_FILE_GET = 'cmd_file_get';
export const CMD_FILE_CREATE_NEW = 'cmd_file_create_new';
export const CMD_GET_TRIP_STATE = 'cmd_get_trip_state';
export const CMD_SYNC_TRIP_STATE = 'cmd_sync_trip_state';
export const CMD_FORMAT_IOT_MEMORY = 'cmd_format_iot_memory';
export const CMD_COLLECT_IOT_STATISTICS = 'cmd_collect_iot_statistics';

//MESSAGES WITH REPLIES
export const GET_MESSAGES_WITH_REPLIES = 'get_messages_with_replies';

//TURNSTILE RELEASE MESSAGES
export const GET_TURNSTILE_RELEASE_MESSAGES = 'get_turnstile_release_messages';

//TRANSACTION MESSAGES
export const GET_TRANSACTION_MESSAGES = 'get_transaction_messages';

//TRANSACTIONS
export const GET_TRANSACTIONS = 'get_transactions';
export const GET_TRANSACTION = 'get_transaction';
export const REQUEST_GET_TRANSACTION = 'request_get_transaction';
export const REQUEST_GET_TRANSACTION_FAILURE = 'request_get_transaction';

// MESSAGE QUEUE
export const CMD_MESSAGE_QUEUE_SEND_NOW = 'cmd_message_queue_send_now';
export const CMD_MESSAGE_QUEUE_EXPIRE = 'cmd_message_queue_expire';

// ORGANIZATIONS
export const GET_ORGANIZATIONS = "get_organizations";

// LOCATIONS
export const GET_LOCATIONS = "get_locations";