import {
  CLEAR_STATE,
  GET_IOT_MESSAGE,
  REQUEST_GET_IOT_MESSAGE,
  REQUEST_GET_IOT_MESSAGE_FAILURE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  iotMessage: {},
  loadingIotMessage: false,
  loadingIotMessageError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_IOT_MESSAGE: {
      return {
        ...state,
        iotMessage: action.payload,
        loadingIotMessage: false,
        loadingIotMessageError: false,
      };
    }

    case REQUEST_GET_IOT_MESSAGE: {
      return {
        ...state,
        loadingIotMessage: true,
        loadingIotMessageError: false,
      };
    }

    case REQUEST_GET_IOT_MESSAGE_FAILURE: {
      return {
        ...state,
        loadingIotMessage: false,
        loadingIotMessageError: true,
      };
    }

    default: {
      return state;
    }
  }
};
