import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(request => {
  const jwtToken = localStorage.getItem('token');
  if (jwtToken) request.headers.Authorization = `Bearer ${jwtToken}`;
  return request;
});

export default axiosClient;
