import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_IOT_MESSAGES,
  GET_IOT_MESSAGE_DETAIL,
} from '../../@jumbo/constants/ActionTypes';
import axios from 'services/auth/jwt/config';

const iotMessagesBaseEndpoint = 'device-messages';

export const getIotMessages = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${iotMessagesBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_IOT_MESSAGES,
          payload: { data: res.data.data, totalCount: res.data.count },
        });
      } else {
        console.error('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch({ type: HIDE_MESSAGE });
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: HIDE_MESSAGE });
    }
  };
};

export const getIotMessage = iotMessageId => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${iotMessagesBaseEndpoint}/${iotMessageId}`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_IOT_MESSAGE_DETAIL, payload: res.data });
      } else {
        console.log('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch({ type: HIDE_MESSAGE });
      }
    } catch (error) {
      console.log('Error****:', error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: HIDE_MESSAGE });
    }
  };
};
