import {
  CLEAR_STATE,
  GET_DEVICES,
  GET_DEVICES_REAL_TIME,
  GET_DEVICE_LATEST_MESSAGES_REQUEST,
  GET_DEVICE_LATEST_MESSAGES_SUCCESS,
  GET_DEVICE_LATEST_MESSAGES_ERROR,
  SHOW_IOT_DEVICE_COMMAND_DIALOG,
  HIDE_IOT_DEVICE_COMMAND_DIALOG,
  DEVICE_COMMAND_ERROR,
  UPDATE_DEVICES_TABLE_STATE,
  GET_IOT_DEVICE,
  REQUEST_IOT_DEVICE,
  REQUEST_IOT_DEVICE_FAILURE,
  REQUEST_UPDATE_IOT_DEVICE,
  REQUEST_UPDATE_IOT_DEVICE_FAILURE,
  SHOW_UPDATE_IOT_DEVICE_FORM,
  HIDE_UPDATE_IOT_DEVICE_FORM,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  devices: {
    data: [],
    totalCount: 0,
  },
  iotDeviceReceivingCommand: {
    isSendingIotDeviceCommand: false,
    currentIotDeviceReceivingCommand: null,
    sentDeviceCommandError: null,
  },
  iotDeviceUpdating: {
    isUpdatingAnIotDevice: false,
    currentUpdatingIotDevice: null,
    currentUpdatingIotDeviceData: null,
    loadingCurrentUpdatingIotDeviceData: false,
    loadingCurrentUpdatingIotDeviceDataError: false,
    loadingUpdatingIotDevice: false,
    loadingUpdatingIotDeviceError: false,
  },
  devicesTableState: {
    page: 0,
    size: 10,
    sort: 'hardwareId,desc',
    filter: 'online=true&',
  },
  loadingLatestMessage: [],
  loadingLatestMessageError: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_DEVICES: {
      return {
        ...state,
        devices: action.payload,
      };
    }
    case GET_DEVICES_REAL_TIME: {
      return {
        ...state,
        devices: action.payload,
      };
    }

    case GET_DEVICE_LATEST_MESSAGES_REQUEST: {
      const deviceIdentifier = action.payload;

      const updatedLoadingLatestMessage = [...state.loadingLatestMessage, deviceIdentifier];

      const updatedLoadingLatestMessageError = state.loadingLatestMessageError.filter(
        loadingLatestMessage =>
          deviceIdentifier.hardwareId !== loadingLatestMessage.hardwareId ||
          deviceIdentifier.deviceType !== loadingLatestMessage.deviceType,
      );

      return {
        ...state,
        loadingLatestMessage: updatedLoadingLatestMessage,
        loadingLatestMessageError: updatedLoadingLatestMessageError,
      };
    }

    case GET_DEVICE_LATEST_MESSAGES_SUCCESS: {
      const deviceIdentifier = action.payload.deviceIdentifier;
      const latestMessage = action.payload.data;

      const updatedLoadingLatestMessage = state.loadingLatestMessage.filter(
        loadingLatestMessage =>
          deviceIdentifier.hardwareId !== loadingLatestMessage.hardwareId ||
          deviceIdentifier.deviceType !== loadingLatestMessage.deviceType,
      );

      const updatedDevices = state.devices.data.map(device => {
        if (deviceIdentifier.hardwareId !== device.hardwareId || deviceIdentifier.deviceType !== device.deviceType)
          return device;
        return {
          ...device,
          type: latestMessage.type,
          status: latestMessage.status,
          serverTime: latestMessage.serverTime,
          lastDeviceMessage: latestMessage,
        };
      });

      return {
        ...state,
        devices: {
          ...state.devices,
          data: updatedDevices,
        },
        loadingLatestMessage: updatedLoadingLatestMessage,
      };
    }

    case GET_DEVICE_LATEST_MESSAGES_ERROR: {
      const deviceIdentifier = action.payload;

      const updatedLoadingLatestMessage = state.loadingLatestMessage.filter(
        loadingLatestMessage =>
          deviceIdentifier.hardwareId !== loadingLatestMessage.hardwareId ||
          deviceIdentifier.deviceType !== loadingLatestMessage.deviceType,
      );

      const updatedLoadingLatestMessageError = [...state.loadingLatestMessageError, deviceIdentifier];

      return {
        ...state,
        loadingLatestMessage: updatedLoadingLatestMessage,
        loadingLatestMessageError: updatedLoadingLatestMessageError,
      };
    }

    case SHOW_IOT_DEVICE_COMMAND_DIALOG: {
      const iotDeviceId = action.payload;
      const currentIotDeviceReceivingCommand = state.devices.data.find(device => device.hardwareId === iotDeviceId);

      return {
        ...state,
        iotDeviceReceivingCommand: {
          isSendingIotDeviceCommand: true,
          currentIotDeviceReceivingCommand: currentIotDeviceReceivingCommand,
          sentDeviceCommandError: null,
        },
      };
    }

    case HIDE_IOT_DEVICE_COMMAND_DIALOG: {
      return {
        ...state,
        iotDeviceReceivingCommand: {
          isSendingIotDeviceCommand: false,
          currentIotDeviceReceivingCommand: null,
          sentDeviceCommandError: null,
        },
      };
    }

    case DEVICE_COMMAND_ERROR: {
      return {
        ...state,
        iotDeviceReceivingCommand: {
          ...state.iotDeviceReceivingCommand,
          sentDeviceCommandError: action.payload,
        },
      };
    }

    case UPDATE_DEVICES_TABLE_STATE: {
      return {
        ...state,
        devicesTableState: action.payload,
      };
    }

    case GET_IOT_DEVICE: {
      return {
        ...state,
        iotDeviceUpdating: {
          ...state.iotDeviceUpdating,
          currentUpdatingIotDeviceData: action.payload,
          loadingCurrentUpdatingIotDeviceData: false,
          loadingCurrentUpdatingIotDeviceDataError: false,
        },
      };
    }

    case REQUEST_IOT_DEVICE: {
      return {
        ...state,
        iotDeviceUpdating: {
          ...state.iotDeviceUpdating,
          loadingCurrentUpdatingIotDeviceData: true,
          loadingCurrentUpdatingIotDeviceDataError: false,
        },
      };
    }

    case REQUEST_IOT_DEVICE_FAILURE: {
      return {
        ...state,
        iotDeviceUpdating: {
          ...state.iotDeviceUpdating,
          loadingCurrentUpdatingIotDeviceData: false,
          loadingCurrentUpdatingIotDeviceDataError: action.payload,
        },
      };
    }

    case REQUEST_UPDATE_IOT_DEVICE: {
      return {
        ...state,
        iotDeviceUpdating: {
          ...state.iotDeviceUpdating,
          loadingUpdatingIotDevice: true,
          loadingUpdatingIotDeviceError: false,
        },
      };
    }

    case REQUEST_UPDATE_IOT_DEVICE_FAILURE: {
      return {
        ...state,
        iotDeviceUpdating: {
          ...state.iotDeviceUpdating,
          loadingUpdatingIotDevice: false,
          loadingUpdatingIotDeviceError: action.payload,
        },
      };
    }

    case SHOW_UPDATE_IOT_DEVICE_FORM: {
      const iotDeviceHardwareId = action.payload;
      const currentUpdatingIotDevice = state.devices.data.find(iotDevice => iotDevice.hardwareId === iotDeviceHardwareId);

      return {
        ...state,
        iotDeviceUpdating: {
          ...state.iotDeviceUpdating,
          isUpdatingAnIotDevice: true,
          currentUpdatingIotDevice: currentUpdatingIotDevice,
        },
      };
    }

    case HIDE_UPDATE_IOT_DEVICE_FORM: {
      return {
        ...state,
        iotDeviceUpdating: {
          isUpdatingAnIotDevice: false,
          currentUpdatingIotDevice: null,
          currentUpdatingIotDeviceData: null,
          loadingCurrentUpdatingIotDeviceData: false,
          loadingCurrentUpdatingIotDeviceDataError: false,
          loadingUpdatingIotDevice: false,
          loadingUpdatingIotDeviceError: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};
