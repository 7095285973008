import React from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import PageLoader from '../PageComponents/PageLoader';

const ContentLoader = () => {
  const { error, loading, message } = useSelector(({ common }) => common);

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {error && <Snackbar open={Boolean(error)} message={error} />}
      {message && <Snackbar open={Boolean(message)} message={message} />}
    </React.Fragment>
  );
};

export default ContentLoader;
