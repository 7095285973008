import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_TRANSACTION_MESSAGES,
} from '../../@jumbo/constants/ActionTypes';
import axios from 'services/auth/jwt/config';

const transactionMessagesBaseEndpoint = 'device-transaction-messages';

export const getTransactionMessages = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`${transactionMessagesBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_TRANSACTION_MESSAGES,
          payload: res.data,
        });
      } else {
        console.error('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch({ type: HIDE_MESSAGE });
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: HIDE_MESSAGE });
    }
  };
};
