import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorView from 'util/ErrorView';
import { QueryClient, QueryClientProvider } from 'react-query';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const store = configureStore();
export const queryClient = new QueryClient();

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppWrapper>
              <Switch>
                <Routes />
              </Switch>
            </AppWrapper>
          </ConnectedRouter>
        </Provider>
      </AppContextProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
