import { CLEAR_STATE, GET_TRANSACTION_MESSAGES } from '../../@jumbo/constants/ActionTypes';
import IotMonitorUtils from 'util/IotMonitorUtils';

const INIT_STATE = {
  transactionMessages: {
    data: [],
    flattenedData: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_TRANSACTION_MESSAGES: {
      return {
        ...state,
        transactionMessages: {
          ...state.transactionMessages,
          data: action.payload.data,
          flattenedData: action.payload.data.map(transactionMessage => IotMonitorUtils.flattenObject(transactionMessage)),
          totalCount: action.payload.count,
        },
      };
    }

    default: {
      return state;
    }
  }
};
