import { CLEAR_STATE, GET_MESSAGES_WITH_REPLIES } from '../../@jumbo/constants/ActionTypes';
import IotMonitorUtils from 'util/IotMonitorUtils';

const INIT_STATE = {
  messagesWithReplies: {
    data: [],
    flattenedData: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_MESSAGES_WITH_REPLIES: {
      return {
        ...state,
        messagesWithReplies: {
          data: action.payload.data,
          flattenedData: action.payload.data.map(messageWithReply => IotMonitorUtils.flattenObject(messageWithReply)),
          totalCount: action.payload.count,
        },
      };
    }

    default: {
      return state;
    }
  }
};
