import {
  CLEAR_STATE,
  SEND_FORGET_PASSWORD_EMAIL,
  SET_USER_TOKEN,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
} from '../../@jumbo/constants/ActionTypes';

const STORAGE_VERSION = '1';

const STORAGE_ITEM_VERSION = 'version';
const STORAGE_ITEM_TOKEN = 'token';
const STORAGE_ITEM_USER = 'user';

const clearLocalStorage = () => {
  localStorage.removeItem(STORAGE_ITEM_TOKEN);
  localStorage.removeItem(STORAGE_ITEM_USER);
  localStorage.setItem(STORAGE_ITEM_VERSION, STORAGE_VERSION);
};

if (localStorage.getItem(STORAGE_ITEM_VERSION) !== STORAGE_VERSION) {
  clearLocalStorage();
}

const INIT_STATE = {
  token: localStorage.getItem(STORAGE_ITEM_TOKEN),
  authUser: JSON.parse(localStorage.getItem(STORAGE_ITEM_USER)),
  loadUser: false,
  send_forget_password_email: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      clearLocalStorage();
      return {
        ...state,
        token: null,
        authUser: null,
        loadUser: false,
        send_forget_password_email: false,
      };
    }
    case SET_USER_TOKEN: {
      localStorage.setItem(STORAGE_ITEM_TOKEN, action.payload);
      return {
        ...state,
        token: action.payload,
      };
    }
    case UPDATE_AUTH_USER: {
      localStorage.setItem(STORAGE_ITEM_USER, JSON.stringify(action.payload));
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
