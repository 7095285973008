import{
    GET_LOCATIONS
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    locations:{
    data:[],
    }
};

export default(state=INIT_STATE, action) => {
    switch(action.type){
        case GET_LOCATIONS :{
            return{
                ...state,
                locations:{
                    ...state.locations,
                    data: action.payload,
                },
            };
        }
        default: {
              return state;
        }
    }
};