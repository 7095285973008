import { CLEAR_STATE, GET_TURNSTILE_RELEASE_MESSAGES } from '../../@jumbo/constants/ActionTypes';
import IotMonitorUtils from 'util/IotMonitorUtils';

const INIT_STATE = {
  turnstileReleaseMessages: {
    data: [],
    flattenedData: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_TURNSTILE_RELEASE_MESSAGES: {
      return {
        ...state,
        turnstileReleaseMessages: {
          ...state.turnstileReleaseMessages,
          data: action.payload.data,
          flattenedData: action.payload.data.map(turnstileReleaseMessage =>
            IotMonitorUtils.flattenObject(turnstileReleaseMessage),
          ),
          totalCount: action.payload.count,
        },
      };
    }

    default: {
      return state;
    }
  }
};
